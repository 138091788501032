import React from 'react';
import Fade from 'react-reveal/Fade';
import i18next from "i18next";

const About = () => {

    return (
        <section id="about" className="aboutSection">
        
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sectionTitle">
                            <h3>{i18next.t('About')} {i18next.t('AL Ghadeer')}</h3>
                            {/* <p>{i18next.t('A Real Estate Investments Company Based IN Dubai')}</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Fade left>
                    <div className="col-lg-5">
                        <div className="aboutImg">
                            <img src="/images/about.jpg" alt="aboutImg" />
                        </div>
                    </div>
                    </Fade>
                    <Fade right>
                    <div className="col-lg-7 align-items-center">
                        <div className="aboutInfo">
                            <h3>{i18next.t('AL Ghadeer')}</h3>
                            <h6>{i18next.t('A')} <span>{i18next.t('Real Estate Investments')} </span>{i18next.t('Company based in')} <span>{i18next.t('Dubai')}</span></h6>
                            <p className="my-5 aboutInfoText">{i18next.t('Al-Ghadeer is a company specialized in the field of investment and real estate development. Al-Ghadeer Company was established For real estate investments In 2023, as a real estate development company aspiring to provide unique living experiences, it has witnessed... Rapid growth. Today, Al Ghadeer Real Estate Investments Company is working on developing residential projects that provide Providing customers with a unique living experience within an integrated natural environment, commercial projects, and education. tourism and tourism and its management.')}</p>
                            <div className="row aboutList">
                                <div className="col-lg-12">
                                    {/* <div className="aboutLabel">
                                        <label>{i18next.t('Phone')}</label>
                                        <p>00000+000</p>
                                    </div>
                                    <div className="aboutLabel">
                                        <label>{i18next.t('Phone')}</label>
                                        <p>00000+000 – 00000+000</p>
                                    </div> */}
                                    <div className="aboutLabel">
                                        <label>{i18next.t('E-mail')}</label>
                                        <p>info@ghadeerestates.com</p>
                                    </div>
                                    <div className="aboutLabel">
                                        <label>{i18next.t('Address')}</label>
                                        <p>{i18next.t('Dubai - United Arab Emirates')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>

        </section>
    );
};

export default About;