import React from 'react';

const Footer = () => {

    return (
        <footer>
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-12">
                        <ul>
                            <li className="list-inline-item"><a href="#"><i className="bi bi-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#"><i className="bi bi-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#"><i className="bi bi-linkedin"></i></a></li>
                            <li className="list-inline-item"><a href="#"><i className="bi bi-instagram"></i></a></li>
                            <li className="list-inline-item"><a href='#' target='_blank_'><i className="bi bi-youtube"></i></a></li>
                            <li className="list-inline-item"><a href='#' target='_blank_'><i className="bi bi-whatsapp"></i></a></li>
                    
                        </ul>
                        <p>2023 © Alghadeer, All Rights Reserved.</p>
                        {/* <p>Copyright © 2023 ORG By <a href='#/'>Mustafa Abdullah</a> | All rights reserved.</p> */}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;