import React from 'react';
import Fade from 'react-reveal/Fade';
import LightSpeed from 'react-reveal/LightSpeed';
import i18next from "i18next";

const Services = () => {

    return (
        <section id="service" className="serviceSection">
        
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sectionTitle">
                            <h3>{i18next.t('OUR SERVICES')}</h3>
                            {/* <p>{i18next.t('A Real Estate Investments Company Based IN Dubai')}</p> */}
                            {/* <p>{i18next.t('The management of the company considers the company to be a potential partner in future real estate developments in the United Arab Emirates, specifically in Dubai. Their focus is on providing tailored solutions for the requirements and the development of the company, aligning with the needs of potential investors and stakeholders in the real estate development sector. This is to ensure the necessary foundation for the future success of the required real estate investments.')}</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <LightSpeed  left>
                    <div className="col-lg-4">
                        <div class="blogBox">
                            <div class="blogImage">
                                <img src="/images/services/Studies.jpg" alt="blog" />
                            </div>
                            <div class="blogInfo">
                                <div class="blogInfoTitle">
                                    <h6>{i18next.t('Studies')}</h6>
                                    <p>{i18next.t('Real estate study and analysis of site-specific needs and opportunities. We conduct detailed studies that include financial evaluations and concepts that attract investors attention to the location and its surrounding environment.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </LightSpeed >
                    <Fade bottom>
                    <div className="col-lg-4">
                        <div class="blogBox">
                            <div class="blogImage">
                                <img src="/images/services/Real Estate Consultation.jpg" alt="blog" />
                            </div>
                            <div class="blogInfo">
                                <div class="blogInfoTitle">
                                    <h6>{i18next.t('Real Estate Consultation')}</h6>
                                    <p>{i18next.t('We provide specialized engineering consultations to help clients understand technical concepts and engineering plans. This includes explaining engineering concepts and developing preliminary plans and perspectives.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Fade>
                    <LightSpeed right>
                    <div className="col-lg-4">
                        <div class="blogBox">
                            <div class="blogImage">
                                <img src="/images/services/Real Estate Development.jpg" alt="blog" />
                            </div>
                            <div class="blogInfo">
                                <div class="blogInfoTitle">
                                    <h6>{i18next.t('Real Estate Development')}</h6>
                                    <p>{i18next.t('We manage real estate project development from start to finish. This encompasses overseeing construction work, site management, and ensuring precise plan execution.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </LightSpeed>
                    <Fade bottom>
                    <div className="col-lg-4">
                        <div class="blogBox">
                            <div class="blogImage">
                                <img src="/images/services/Engineering Design.jpg" alt="blog" />
                            </div>
                            <div class="blogInfo">
                                <div class="blogInfoTitle">
                                    <h6>{i18next.t('Engineering Design')}</h6>
                                    <p>{i18next.t('Precise and creative engineering design is at the core of our work. We develop designs that meet the clients needs and adhere to the highest quality and technical standards.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div class="blogBox">
                            <div class="blogImage">
                                <img src="/images/services/Marketing.jpg" alt="blog" />
                            </div>
                            <div class="blogInfo">
                                <div class="blogInfoTitle">
                                    <h6>{i18next.t('Marketing')}</h6>
                                    <p>{i18next.t('We provide comprehensive marketing services, including administrative and financial management, and the execution of marketing activities. Our role is to manage projects to ensure investment success.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div class="blogBox">
                            <div class="blogImage">
                                <img src="/images/services/Import and Export.jpg" alt="blog" />
                            </div>
                            <div class="blogInfo">
                                <div class="blogInfoTitle">
                                    <h6>{i18next.t('Import and Export')}</h6>
                                    <p>{i18next.t('We carefully handle the import and export of construction materials, such as iron or aluminum. We ensure the delivery of materials with the required quality and within the specified timeframe for investors or customers.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>

        </section>
    );
};

export default Services;