import React from 'react';
import i18next from "i18next";
// import OwlCarousel from 'react-owl-carousel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useEffect } from 'react';

const Banner = () => {

    useEffect(() => {
        // bg img
        const pageSection = document.querySelectorAll(".bg-img, section");
        pageSection.forEach(function(element, index) {
            if (element.getAttribute("data-background")) {
                element.style.backgroundImage = "url(" + element.dataset.background + ")";
            }
        });
    },[])

    return (
        <section id="home" className="homeSection">
            <div className="hero">
            <OwlCarousel className="owl-carousel owl-theme"
            items= {1}
            loop={false}
            dots= {false}
            margin= {0}
            autoplay= {true}
            autoplayTimeout = {3000}
            animateOut = "fadeOut"
            responsiveClass = {true}
            nav= {false}
            // navText = <i className="ti-angle-left" aria-hidden="true"></i>', '<i className="ti-angle-right" aria-hidden="true"></i>
            >
            {/* <!-- The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. --> */}
            <div className="homeBackground bg-img" data-background="images/slider/1.jpg"></div>
            <div className="homeBackground bg-img" data-background="images/slider/2.jpg"></div>
            <div className="homeBackground bg-img" data-background="images/slider/3.jpg"></div>
            </OwlCarousel>
                
                <div className="homeOvrlay"></div>
                <div className="homecontain">
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="infoConatin">
                                <div className="infoImg">
                                    <div className="imgCover">
                                        <img src="/images/logo.png" alt="person" />
                                    </div>
                                </div>
                                <div className="infoText">
                                    <h2>{i18next.t('AL Ghadeer')}</h2>
                                    <p>{i18next.t('Real Estate Investments Company')}</p>
                                </div>
                                {/* <div className="infoButton">
                                    <button className="homeBtnCV">{i18next.t('Download')} {i18next.t('CV')}</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;