import React, { useEffect , useState } from 'react';
import Nav from '../nav/nav';
import Banner from '../banner/banner';
import About from '../about/abouts';
import Services from '../services/services';
import Projects from '../projects/projects';
import Contact from '../contact/contacts';
import Footer from '../footer/footer';

const Home = () => {
    const [isHeaderToggled, setIsHeaderToggled] = useState(false);

    useEffect(() => {
        // Menu Header fixed
        const menuIcon = document.querySelector('.menuIcon');
        window.addEventListener('scroll',() => {
            if(window.pageYOffset>50){
                menuIcon.classList.add('menuIconPositon')
            }else {
                menuIcon.classList.remove('menuIconPositon')
            }
        })
        // toUP show and hide
        const toUp = document.querySelector('.toUp');
        window.addEventListener('scroll',() => {
            if(window.pageYOffset>700){
                toUp.classList.add('toUpActive')
            }else {
                toUp.classList.remove('toUpActive')
            }
        })
        // toUp Work
        toUp.addEventListener('click',() => {
            window.scrollTo({
                top:0,
                left:0,
                behavior:"smooth"
            })
        })

        // Section Active
        let sections = document.querySelectorAll('section');
        onscroll = ()=>{
            let scrollPostion = document.documentElement.scrollTop;
            sections.forEach(section => {
                if(scrollPostion >= section.offsetTop - section.offsetHeight * 0.25 && scrollPostion < section.offsetTop + section.offsetHeight - section.offsetHeight * 0.25){
                    let currentId = section.attributes.id.value;
                    removeAllActiveClasses();
                    addActiveClass(currentId);
                }
            });
        }
        let removeAllActiveClasses = () => {
            document.querySelectorAll('.nav a').forEach(el => {
                el.classList.remove('active')
            })
        }
        let addActiveClass = (id) => {
            let selector = `.nav a[href="#${id}"]`
            document.querySelector(selector).classList.add('active')
        }
        // Portfolio Filter Gallery
        const portfolioBtn = document.querySelectorAll('.workMenu li a');
        const portfolioItem = document.querySelectorAll('.workBox');
        portfolioBtn.forEach(item=>{
            item.addEventListener('click',()=>{
                for(let i = 0; i < portfolioBtn.length; i++){
                    portfolioBtn[i].classList.remove('workActive');
                }
                item.classList.add('workActive');
                // Filter
                portfolioItem.forEach(show=>{
                    show.style.display = "none";
                    let portfolioItems = item.textContent.toLowerCase();
                    if(show.getAttribute("data-att") === portfolioItems || portfolioItems === "all"){
                        show.style.display = "block";
                    }
                })
            })
        })
    } , [])

    const toggleHeader = () => {
        setIsHeaderToggled(!isHeaderToggled);
      };

    return (
        <>
        <a className="toUp" href="#/"><i className="bi bi-chevron-up"></i></a>
        <div className="menuIcon" onClick={toggleHeader}>
            <i className="bi bi-list"></i>
        </div>
        <Nav isHeaderToggled = {isHeaderToggled} />
        <div className={isHeaderToggled ? 'rightBody rightBodyToggle' : 'rightBody'}>
            {/* home - slider - banner */}
            <Banner />
            {/* About */}
            <About />
            {/* Services */}
            <Services />
            {/* Projects */}
            {/* <Projects /> */}
            {/* Contact */}
            <Contact />
            {/* Footer */}
            <Footer />
        </div>
        </>
    );
};

export default Home;