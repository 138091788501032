import React from 'react';
import i18next from "i18next";

const Nav = (props) => {

    const changeLang = (e) => {
        localStorage.setItem('lang',e.target.value);
        window.location.reload();
    }
    const lang = localStorage.getItem('lang') || 'en';

    return (
    <header className={props.isHeaderToggled ? 'headerToggle' : ''}>
        <ul className="nav">

        <li>
        <a className="nav-link active" href="#home">
            <i className="bi bi-house-door"></i>
            <span>{i18next.t('Home')}</span>
        </a>
        </li>
        <li>
        <a className="nav-link" href="#about">
            <i className="bi bi-credit-card-2-front"></i>
            <span>{i18next.t('About')}</span>
        </a>
        </li>
        <li>
        <a className="nav-link" href="#service">
            <i className="bi bi-cart-check"></i>
            <span>{i18next.t('Services')}</span>
        </a>
        </li>
        {/* <li>
        <a className="nav-link" href="#portfolio">
            <i className="bi bi-briefcase"></i>
            <span>{i18next.t('Portfolio')}</span>
        </a>
        </li> */}
        <li>
        <a className="nav-link" href="#contact">
            <i className="bi bi-envelope"></i>
            <span>{i18next.t('Contact')}</span>
        </a>
        </li>

        <li className="nav-link">
        <select className="nav-item selectI18n" onChange={changeLang} value={lang}>
            <option value="en">En</option>
            <option value="ar">عربي</option>
        </select>
        </li>

        </ul>

        <a className="logo" href="#">AL Ghadeer</a>
    </header>
    );
};

export default Nav;