import React, {useEffect , useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/home/home';
// import ProjectPage from './components/projects/project-page';
import axios from 'axios';
import './App.css'

// url
// const url = "https://backend.alridainvestment.com";

const App = () => {

  // const [data , setData] = useState([]);

  // useEffect(() => {
        
  //   const lang = localStorage.getItem('lang') || 'en';
  //   axios.get(`${url}/homepage/homepage/${lang}/`)
  //   .then(res => setData(res.data))

  // },[])

  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route exact path="*" element={<Home />} />
        <Route exact path="/" element={<Home />} />
        {/* <Route path="/projectpage/:id" element={<ProjectPage />} /> */}
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
