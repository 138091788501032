import React from 'react';
import i18next from "i18next";

const Contact = () => {

    return (
        <section id="contact" className="contactSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sectionTitle">
                            <h3>{i18next.t('Contact US')}</h3>
                            {/* <p>{i18next.t('A Real Estate Investments Company Based IN Dubai')}</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            {/* <div className="col-lg-4">
                                <div className="contactSoical">
                                    <div className="contactSoicalInfo">
                                        <i className="bi bi-telephone"></i>
                                        <p>{i18next.t('Phone')}</p>
                                        <span>00000000+000</span>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-6">
                                <div className="contactSoical b3">
                                    <div className="contactSoicalInfo">
                                        <i className="bi bi-envelope"></i>
                                        <p>{i18next.t('E-mail')}</p>
                                        <span>info@ghadeerestates.com</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contactSoical gray">
                                    <div className="contactSoicalInfo">
                                        <i className="bi bi-map"></i>
                                        <p>{i18next.t('Address')}</p>
                                        <span>Dubai - United Arab</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group contactForm">
                                <input type="text" className="form-control mt-3" placeholder={i18next.t('Name')} />
                                <input type="text" className="form-control mt-2" placeholder={i18next.t('Email')} />
                                <textarea className="form-control mt-2" rows="3" placeholder={i18next.t('Message')}></textarea>
                                <button type="submit" className="btn mt-3">{i18next.t('Send Message')}</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462563.03267140884!2d55.557141752474216!3d25.07565840752442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2z2K_YqNmKIC0g2KfZhNil2YXYp9ix2KfYqiDYp9mE2LnYsdio2YrYqSDYp9mE2YXYqtit2K_YqQ!5e0!3m2!1sar!2siq!4v1699052678790!5m2!1sar!2siq" frameBorder="0" style={{border:"0"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;